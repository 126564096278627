import { CONTENT_DRAWER_FOOTER_HEIGHT } from "@/content-usage/drawer/footer/InlineContentDrawerFooter"
import useIsWebView from "@/product/util/hook/useIsWebView"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { forwardRef } from "react"

interface QuizDrawerQuestionsFooterProps extends TestIDProps {
  children?: React.ReactNode
  buttons?: React.ReactNode
  classes?: ClassNameMap<"footer">
}

export default forwardRef<HTMLDivElement, QuizDrawerQuestionsFooterProps>(
  function QuizDrawerQuestionsFooter(
    { testid = "QuizDrawerQuestionsFooter", children, buttons, classes: customClasses },
    ref
  ) {
    const isWebView = useIsWebView()
    const classes = useStyles({ isWebView })

    return (
      <div
        className={classNames(classes.footer, customClasses?.footer)}
        data-testid={testid}
      >
        {Boolean(children) && <div className={classes.contentContainer}>{children}</div>}
        <div
          ref={ref}
          className={classes.buttonsContainer}
          data-testid={`${testid}.buttonsContainer`}
        >
          {buttons}
        </div>
      </div>
    )
  }
)

interface StyleProps {
  isWebView: boolean
}

const useStyles = makeUseStyles((theme) => ({
  footer: ({ isWebView }: StyleProps) => ({
    borderTop: theme.palette.constants.borderSmall,
    height: CONTENT_DRAWER_FOOTER_HEIGHT,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2.5),
    position: isWebView ? "fixed" : "absolute",
    bottom: 0,
    zIndex: 2,
  }),
  contentContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    flex: "1 1 auto",
    justifyContent: "flex-end",
  },
}))
