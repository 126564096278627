import useQuizEditorDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import QuizReviewSubmissionsHeaderTabs from "@/content-usage/drawer/quizzes/review-submissions/QuizReviewSubmissionsHeaderTabs"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawerHeader } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"

interface QuizReviewSubmissionsDrawerHeaderProps extends TestIDProps {
  title?: string
  overlineTitle?: string
}

function QuizReviewSubmissionsDrawerHeader({
  testid = "QuizReviewSubmissionsDrawerHeader",
  title = "Untitled",
  overlineTitle = "Quiz",
}: QuizReviewSubmissionsDrawerHeaderProps) {
  const { navigateAway } = useQuizEditorDrawer()
  const { close } = useContentUsageDrawer()
  const isMobile = useIsMobile()

  const handleBack = () => navigateAway("submissions")

  const classes = useStyles()
  return (
    <DiscoDrawerHeader
      testid={`${testid}.header`}
      title={title}
      overlineTitle={isMobile ? undefined : overlineTitle}
      onClose={close}
      onBack={handleBack}
      className={classes.drawerHeaderContainer}
      headerActions={!isMobile && <QuizReviewSubmissionsHeaderTabs />}
      classes={{ header: classes.header }}
    >
      {isMobile && <QuizReviewSubmissionsHeaderTabs />}
    </DiscoDrawerHeader>
  )
}
const useStyles = makeUseStyles((theme) => ({
  drawerHeaderContainer: {
    padding: theme.spacing(2.5),
    paddingBottom: 0,
  },
  header: {
    paddingBottom: 0,
  },
}))

export default QuizReviewSubmissionsDrawerHeader
