import { useContentUsageDrawerContext } from "@/content-usage/drawer/ContentUsageDrawerContext"
import { useContentUsageDrawerNavigationQuery } from "@/content-usage/drawer/footer/__generated__/useContentUsageDrawerNavigationQuery.graphql"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { getNextCurriculumItem } from "@/content/util/curriculumUtils"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

export function useContentUsageDrawerNavigation() {
  const contentUsageDrawer = useContentUsageDrawer()
  const { handleLeave } = useUnsavedChangesModalContext()
  const { drawerParentEntity, isCurriculum } = useContentUsageDrawerContext()
  const activeProduct = useActiveProduct()

  const { curriculum } = useLazyLoadQuery<useContentUsageDrawerNavigationQuery>(
    graphql`
      query useContentUsageDrawerNavigationQuery($id: ID!) {
        curriculum: node(id: $id) {
          ... on Curriculum {
            id
            ...usePermissionsFragment
            ...curriculumUtils__getNextCurriculumItem
            modules {
              edges {
                node {
                  id
                  content {
                    id
                    children {
                      edges {
                        node {
                          id
                          isLocked
                          content {
                            id
                            systemTaskKind
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: drawerParentEntity?.module?.parentEntityId || "",
    },
    {
      fetchPolicy: "store-and-network",
    }
  )

  const canManage = activeProduct?.viewerPermissions.has("content.manage")
  const contentModules = Relay.connectionToArray(curriculum?.modules)
  const contentUsages = contentModules
    .flatMap((cm) => Relay.connectionToArray(cm.content.children))
    .filter((cu) => cu.content?.systemTaskKind !== "slack_invite")

  const currUsageIdx = contentUsages.findIndex(
    (cu) => cu.id === contentUsageDrawer.params.drawerContentUsageId
  )

  const prevUsage = getPrevUsage()
  const nextUsage = getNextUsage()

  function getPrevUsage() {
    if (currUsageIdx === -1) return
    // Find the previous item that can be opened in drawer.
    const prevNavUsage = findPrevUsage()
    if (!prevNavUsage || (!canManage && prevNavUsage.isLocked)) return

    return prevNavUsage
  }

  function findPrevUsage() {
    for (let i = contentUsages.length - 1; i >= 0; i--) {
      if (i >= currUsageIdx) continue
      return contentUsages[i]
    }
    return null
  }

  function getNextUsage() {
    if (!curriculum || !isCurriculum) return

    const nextNavUsage = getNextCurriculumItem(
      contentUsageDrawer.params.drawerContentUsageId,
      curriculum,
      canManage
    )

    return nextNavUsage
  }

  function handleNav(usage: { id: GlobalID } | undefined) {
    if (!usage) return

    handleLeave({
      onLeave: () => {
        contentUsageDrawer.open({
          drawerContentUsageId: usage.id,
        })
      },
    })
  }

  return { handleNav, nextUsage, prevUsage }
}
